import { lazy } from 'react';

const AcessoNaoAutorizadoIndex = lazy(() => import('../pages/naoAutorizado'));
const AfastamentoAdicionar = lazy(() => import('../pages/afastamento/adicionar'));
const AfastamentoEditar = lazy(() => import('../pages/afastamento/editar'));
const AfastamentoIndex = lazy(() => import('../pages/afastamento/index'));
const AvisoFeriasIndex = lazy(() => import('../pages/avisoFerias/index'));
const AvisoPrevioAdicionar = lazy(() => import('../pages/avisoPrevio/adicionar'));
const AvisoPrevioEditar = lazy(() => import('../pages/avisoPrevio/editar'));
const AvisoPrevioIndex = lazy(() => import('../pages/avisoPrevio/index'));
const Configuracoes = lazy(() => import('../pages/configuracoes/index'));
const ConfiguracaoBatidaRemota = lazy(() => import('../pages/configuracaoBatidaRemota/index'));
const ContribuinteIndividualDetalhes = lazy(() =>
  import('../pages/contribuinteIndividual/detalhes'),
);
const ContribuinteIndividualIndex = lazy(() => import('../pages/contribuinteIndividual/index'));
const ControleAcessoIndex = lazy(() => import('../pages/controleAcesso/index'));
const ControleAcessoAdicionar = lazy(() => import('../pages/controleAcesso/adicionar'));
const ControleAcessoEditar = lazy(() => import('../pages/controleAcesso/editar'));
const EmpregadoAdicionar = lazy(() => import('../pages/empregado/adicionar'));
const EmpregadoPreliminar = lazy(() => import('../pages/empregado/adicionarPreliminar'));
const EmpregadoEditar = lazy(() => import('../pages/empregado/editar'));
const EmpregadoIndex = lazy(() => import('../pages/empregado/index'));
const EventoPontoIndex = lazy(() => import('../pages/eventoPonto/index'));
const FichaFinanceiraIndex = lazy(() => import('../pages/fichaFinanceira/index'));
const FolhaPontoIndex = lazy(() => import('../pages/folhaPonto/index'));
const FrequenciaIndex = lazy(() => import('../pages/frequencia/index'));
const GestaoFrequenciaAuditoria = lazy(() =>
  import('../pages/gestaoFrequencia/auditoriaFrequencia'),
);
const GestaoFrequenciaLocalizacoesFicticias = lazy(() =>
  import('../pages/gestaoFrequencia/localizacoesFicticias'),
);
const GestaoFrequenciaLocalizacoesIndevidas = lazy(() =>
  import('../pages/gestaoFrequencia/localizacoesIndevidas'),
);
const GestaoFrequenciaAuditoriaDispositivos = lazy(() =>
  import('../pages/gestaoFrequencia/auditoriaDispositivos'),
);
const GestorAdicionar = lazy(() => import('../pages/gestor/adicionar'));
const GestorEditar = lazy(() => import('../pages/gestor/editar'));
const GestorIndex = lazy(() => import('../pages/gestor/index'));
const IndicadoresIndex = lazy(() => import('../pages/indicadores/index'));
const InformeRendimentosIndex = lazy(() => import('../pages/informeRendimentos/index'));
const InformeRendimentosUpload = lazy(() => import('../pages/informeRendimentos/Upload/index'));
const JustificativasEmpregadoAdicionar = lazy(() =>
  import('../pages/justificativasEmpregado/adicionar'),
);
const JustificativasEmpregadoIndex = lazy(() => import('../pages/justificativasEmpregado/index'));
const LiquidoFolhaIndex = lazy(() => import('../pages/liquidoFolha/index'));
const MapaFolhaIndex = lazy(() => import('../pages/mapaFolha/index'));
const PontualidadeIndex = lazy(() => import('../pages/pontualidade/index'));
const ProgramacaoEventosAdicionar = lazy(() => import('../pages/programacaoEventos/adicionar'));
const ProgramacaoEventosEditar = lazy(() => import('../pages/programacaoEventos/editar'));
const ProgramacaoEventosIndex = lazy(() => import('../pages/programacaoEventos/index'));
const ProgramacaoFeriasAdicionar = lazy(() => import('../pages/programacaoFerias/Adicionar'));
const ProgramacaoFeriasEditar = lazy(() => import('../pages/programacaoFerias/Editar'));
const ProgramacaoFeriasIndex = lazy(() => import('../pages/programacaoFerias/index'));
const ImportarRegistros = lazy(() => import('../pages/programacaoFerias/importarRegistros'));
const ReciboIndex = lazy(() => import('../pages/recibo/index'));
const ReciboUpload = lazy(() => import('../pages/recibo/Upload/index'));
const RegistroPontoIndex = lazy(() => import('../pages/registroPonto/index'));
const ResumoFolhaIndex = lazy(() => import('../pages/resumoFolha/index'));
const JustificativasEmpresaAceitar = lazy(() => import('../pages/justificativasEmpresa/aceitar'));
const JustificativasEmpresaIndex = lazy(() => import('../pages/justificativasEmpresa/index'));
const ColetorColetivoIndex = lazy(() => import('../pages/coletaColetiva/index'));
const IntegracaoIndex = lazy(() => import('../pages/integracao/index'));
const LancamentoFaixaSalarialIndex = lazy(() => import('../pages/lancamentoFaixaSalarial/index'));
const CandidatoIndex = lazy(() => import('../pages/onboarding/index'));
const ConfiguracaoOnboarding = lazy(() => import('../pages/onboarding/configuracao'));
const CandidatoAdicionar = lazy(() => import('../pages/onboarding/adicionar'));
const CandidatoAnalisar = lazy(() => import('../pages/onboarding/analisar'));
const IntegracaoAutentique = lazy(() => import('../pages/integracaoAutentique'));
const DataAcess = lazy(() => import('../pages/integracaoAutentique/dadosAcesso'));

export default {
  '/acesso-nao-autorizado': AcessoNaoAutorizadoIndex,
  '/afastamentos': AfastamentoIndex,
  '/afastamentos/adicionar': AfastamentoAdicionar,
  '/afastamentos/editar/:id': AfastamentoEditar,
  '/avisos-ferias': AvisoFeriasIndex,
  '/avisos-previos': AvisoPrevioIndex,
  '/avisos-previos/adicionar': AvisoPrevioAdicionar,
  '/avisos-previos/editar/:id': AvisoPrevioEditar,
  '/configuracoes': Configuracoes,
  '/configuracao-batida-remota': ConfiguracaoBatidaRemota,
  '/contribuintes-individuais': ContribuinteIndividualIndex,
  '/contribuintes-individuais/detalhes/:id': ContribuinteIndividualDetalhes,
  '/dashboards/indicadores': IndicadoresIndex,
  '/dashboards/pontualidade': PontualidadeIndex,
  '/empregados': EmpregadoIndex,
  '/empregados/adicionar': EmpregadoAdicionar,
  '/empregados/preliminar': EmpregadoPreliminar,
  '/empregados/editar/:id': EmpregadoEditar,
  '/empresa/controle-acesso': ControleAcessoIndex,
  '/empresa/controle-acesso/adicionar': ControleAcessoAdicionar,
  '/empresa/controle-acesso/editar/:id': ControleAcessoEditar,
  '/eventos-ponto': EventoPontoIndex,
  '/fichas-financeira': FichaFinanceiraIndex,
  '/folhas-ponto': FolhaPontoIndex,
  '/frequencias': FrequenciaIndex,
  '/gestao-frequencias/auditoria': GestaoFrequenciaAuditoria,
  '/gestao-frequencias/localizacoes-ficticias': GestaoFrequenciaLocalizacoesFicticias,
  '/gestao-frequencias/localizacoes-indevidas': GestaoFrequenciaLocalizacoesIndevidas,
  '/gestao-frequencias/auditoria-dispositivos': GestaoFrequenciaAuditoriaDispositivos,
  '/gestores': GestorIndex,
  '/gestores/adicionar': GestorAdicionar,
  '/gestores/editar/:id': GestorEditar,
  '/informes-rendimentos': InformeRendimentosIndex,
  '/informes-rendimentos/enviar': InformeRendimentosUpload,
  '/justificativas': JustificativasEmpresaIndex,
  '/justificativas/aceitar/:id': JustificativasEmpresaAceitar,
  '/justificativas-frequencia': JustificativasEmpregadoIndex,
  '/justificativas-frequencia/adicionar': JustificativasEmpregadoAdicionar,
  '/liquidos-folha': LiquidoFolhaIndex,
  '/mapas-folha': MapaFolhaIndex,
  '/programacoes-eventos': ProgramacaoEventosIndex,
  '/programacoes-eventos/adicionar': ProgramacaoEventosAdicionar,
  '/programacoes-eventos/editar/:id': ProgramacaoEventosEditar,
  '/programacoes-ferias': ProgramacaoFeriasIndex,
  '/programacoes-ferias/adicionar/:id': ProgramacaoFeriasAdicionar,
  '/programacoes-ferias/editar/:id': ProgramacaoFeriasEditar,
  '/programacoes-ferias/importar-registros': ImportarRegistros,
  '/recibos': ReciboIndex,
  '/recibos/enviar': ReciboUpload,
  '/batida-remota': RegistroPontoIndex,
  '/resumos-folha': ResumoFolhaIndex,
  '/coleta-coletiva': ColetorColetivoIndex,
  '/integracao': IntegracaoIndex,
  '/lancamentos-faixas-salariais': LancamentoFaixaSalarialIndex,
  '/candidato': CandidatoIndex,
  '/candidato/adicionar': CandidatoAdicionar,
  '/candidato/editar/:id': CandidatoAnalisar,

  '/configuracao-empresa': ConfiguracaoOnboarding,

  // Integração Autentique
  '/dados-acesso': DataAcess,
  '/lista-documentos': IntegracaoAutentique,
};
