/* eslint-disable */
import {
  CAN_PROCESSANDO_CANDIDATO,
  CAN_SET_CANDIDATO,
  CAN_SET_CANDIDATOS,
  CAN_SET_CARDS,
  CAN_SET_EMPRESAS_LIST,
  CAN_SET_ESTADO_INICIAL,
  CAN_SET_LINK,
  CAN_SET_MODAL_VISIBLE,
  EPG_GET_CANDIDATO_BENEFICIOS,
  EPG_GET_CANDIDATO_SINDICATOS_PARAMETROS,
  EPG_GET_LIST_CANDIDATO_BENEFICIOS,
} from '../actionTypes/actionTypes';

const INITIAL_STATE = {
  processandoCandidato: false,
  visible: false,
  candidatos: [],
  empresas: [],
  targetKeys: [],
  link: [],
  candidato: null,
  parametrosSindicais: [],
  beneficios: [],
  listaBeneficios: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CAN_SET_ESTADO_INICIAL:
      return {
        ...state,
        processandoCandidato: false,
        visible: false,
        candidatos: [],
        empresas: [],
        targetKeys: [],
        link: [],
        candidato: null,
        parametrosSindicais: [],
        beneficios: [],
        listaBeneficios: [],
      };
    case CAN_SET_CANDIDATOS:
      return { ...state, candidatos: payload };
    case CAN_SET_EMPRESAS_LIST:
      return { ...state, nome: payload.nome, id: payload.id };

    case CAN_SET_CARDS:
      return { ...state, targetKeys: payload };
    case CAN_SET_MODAL_VISIBLE:
      return { ...state, visible: payload };
    case CAN_PROCESSANDO_CANDIDATO:
      return { ...state, processandoCandidato: payload };
    case CAN_SET_LINK:
      return { ...state, link: payload };
    case CAN_SET_CANDIDATO:
      return { ...state, candidato: payload };
    case EPG_GET_CANDIDATO_SINDICATOS_PARAMETROS:
      return { ...state, parametrosSindicais: payload };
    case EPG_GET_CANDIDATO_BENEFICIOS:
      return { ...state, beneficios: payload };
    case EPG_GET_LIST_CANDIDATO_BENEFICIOS:
      return { ...state, listaBeneficios: payload };
    default:
      return { ...state };
  }
};
