/* eslint-disable */
import {
  EPG_GET_ARQUIVOS,
  EPG_GET_BENEFICIOS,
  EPG_GET_CARGOS,
  EPG_GET_CONTRATOS_INTERMITENTE,
  EPG_GET_DEPARTAMENTOS,
  EPG_GET_DEPENTENTES,
  EPG_GET_EMPREGADOS,
  EPG_GET_EMPREGADOS_ATIVOS,
  EPG_GET_ESCALAS_REVEZAMENTO,
  EPG_GET_GESTORES_SUCCESS,
  EPG_GET_HORARIOS_SELECIONADO,
  EPG_GET_HORARIOS_TRABALHO,
  EPG_GET_INTERVALOS_SELECIONADOS,
  EPG_GET_LIST_BENEFICIOS,
  EPG_GET_LOTACOES_TRIBUTARIAS,
  EPG_GET_SECOES,
  EPG_GET_SINDICATOS,
  EPG_GET_SINDICATOS_PARAMETROS,
  EPG_PROCESSANDO_CONTRATOS_INTERMITENTE,
  EPG_PROCESSANDO_DEPENDENTES,
  EPG_PROCESSANDO_EMPREGADO,
  EPG_SET_ESTADO_INICIAL,
  EPG_SET_ESTADO_INICIAL_ANEXOS,
  EPG_SET_ESTADO_INICIAL_CONTRATOS_INTERMITENTE,
  EPG_SET_ESTADO_INICIAL_DEPENDENTES,
  EPG_SET_PERIODO_AQUISITIVO,
  EPG_SET_SELECTED_DEPENDENTE,
  EPG_SHOW_MODAL_CONTRATO_INTERMITENTE,
  EPG_SHOW_MODAL_DEPENDENTE,
} from '../actionTypes/actionTypes';

const INITIAL_STATE = {
  processandoEmpregado: false,
  showModalDependente: false,
  selectedDependente: null,
  empregados: [],
  departamentos: [],
  cargos: [],
  secoes: [],
  horariosTrabalho: [],
  escalasRevezamento: [],
  horariosSelecionado: [],
  intervalosSelecionado: [],
  sindicatos: [],
  empregadosAtivos: [],
  lotacoesTributarias: [],
  dependentes: [],
  processandoDependente: false,
  contratosIntermitente: [],
  processandoContratosIntermitente: false,
  showModalContratoIntermitente: false,
  anexos: [],
  periodosAquisitivos: [],
  gestores: [],
  parametrosSindicais: [],
  beneficios: [],
  listaBeneficios: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case EPG_SET_ESTADO_INICIAL:
      return { ...state, processandoEmpregado: false, empregados: [] };
    case EPG_GET_EMPREGADOS:
      return { ...state, empregados: payload };
    case EPG_GET_EMPREGADOS_ATIVOS:
      return { ...state, empregadosAtivos: payload };
    case EPG_PROCESSANDO_EMPREGADO:
      return { ...state, processandoEmpregado: payload };
    case EPG_SHOW_MODAL_DEPENDENTE:
      return { ...state, showModalDependente: payload };
    case EPG_SET_SELECTED_DEPENDENTE:
      return { ...state, selectedDependente: payload };
    case EPG_GET_DEPARTAMENTOS:
      return { ...state, departamentos: payload.data };
    case EPG_GET_CARGOS:
      return { ...state, cargos: payload.data };
    case EPG_GET_SECOES:
      return { ...state, secoes: payload.data };
    case EPG_GET_HORARIOS_TRABALHO:
      return { ...state, horariosTrabalho: payload.data };
    case EPG_GET_ESCALAS_REVEZAMENTO:
      return { ...state, escalasRevezamento: payload.data };
    case EPG_GET_HORARIOS_SELECIONADO:
      return { ...state, horariosSelecionado: payload };
    case EPG_GET_INTERVALOS_SELECIONADOS:
      return { ...state, intervalosSelecionado: payload };
    case EPG_GET_SINDICATOS:
      return { ...state, sindicatos: payload.data };
    case EPG_GET_LOTACOES_TRIBUTARIAS:
      return { ...state, lotacoesTributarias: payload.data };
    case EPG_SET_ESTADO_INICIAL_DEPENDENTES:
      return { ...state, processandoDependente: false, dependentes: [] };
    case EPG_GET_DEPENTENTES:
      return { ...state, dependentes: payload };
    case EPG_PROCESSANDO_DEPENDENTES:
      return { ...state, processandoDependente: payload };
    case EPG_SET_ESTADO_INICIAL_CONTRATOS_INTERMITENTE:
      return { ...state, processandoContratosIntermitente: false, contratosIntermitente: [] };
    case EPG_GET_CONTRATOS_INTERMITENTE:
      return { ...state, contratosIntermitente: payload };
    case EPG_PROCESSANDO_CONTRATOS_INTERMITENTE:
      return { ...state, processandoContratosIntermitente: payload };
    case EPG_SHOW_MODAL_CONTRATO_INTERMITENTE:
      return { ...state, showModalContratoIntermitente: payload };
    case EPG_SET_ESTADO_INICIAL_ANEXOS:
      return { ...state, anexos: [] };
    case EPG_GET_ARQUIVOS:
      return { ...state, anexos: action.payload };
    case EPG_SET_PERIODO_AQUISITIVO:
      return { ...state, periodosAquisitivos: payload };
    case EPG_GET_GESTORES_SUCCESS:
      return { ...state, gestores: payload };
    case EPG_GET_SINDICATOS_PARAMETROS:
      return { ...state, parametrosSindicais: payload };
    case EPG_GET_BENEFICIOS:
      return { ...state, beneficios: payload };
    case EPG_GET_LIST_BENEFICIOS:
      return { ...state, listaBeneficios: payload };
    default:
      return { ...state };
  }
};
