import React from 'react';

const Icon = require('./assets/images/icons/autentique.png');

export default function (props) {
  return (
    <li>
      <a href="#">
        {props.label === 'Integração Autentique' ? (
          <img
            src={Icon}
            alt="Integração Autentique"
            style={{ width: 15, height: 15, marginRight: 5 }}
          />
        ) : (
          <i className={`fa fa-${props.icon}`} />
        )}
        <span className="nav-label">{props.label}</span>
        <span className="fa arrow" />
      </a>
      <ul className="nav nav-second-level collapse">{props.children} </ul>
    </li>
  );
}
