// #region DEFAULT

export const DFT_SET_ESTADO_INICIAL = 'DFT_SET_ESTADO_INICIAL';
export const DFT_SET_MODAL_VISIBLE = 'DFT_SET_MODAL_VISIBLE';
export const DFT_SET_EMPRESAS = 'DFT_SET_EMPRESAS';
export const DFT_SET_CARREGANDO_APLICACAO = 'DFT_SET_CARREGANDO_APLICACAO';

// #endregion

// #region PDF VIEWER

export const PDF_SET_ESTADO_INICIAL = 'PDF_SET_ESTADO_INICIAL';
export const PDF_SET_PDF_URL = 'PDF_SET_PDF_URL';

// #endregion

// #region USUÁRIO

export const USR_GET_PESSOA_LOGADA = 'USR_GET_PESSOA_LOGADA';
export const USR_SET_PESSOA_JA_LOGADA = 'USR_SET_PESSOA_JA_LOGADA';
export const USR_SET_ESTADO_INICIAL_USUARIO_LOGADO = 'USR_SET_ESTADO_INICIAL_USUARIO_LOGADO';
export const USR_GET_DADOS_EMPREGADO = 'USR_GET_DADOS_EMPREGADO';

// #endregion

// #region ROTAS / MENUS

export const ROT_GET_ROTAS_PERMITIDAS = 'ROT_GET_ROTAS_PERMITIDAS';
export const ROT_SET_ROTAS_PERMITIDAS = 'ROT_SET_ROTAS_PERMITIDAS';
export const ROT_SET_MENUS = 'ROT_SET_MENUS';

// #endregion

// #region ENUMERADOR

export const ENM_GET_CIDADES = 'ENM_GET_CIDADES';
export const ENM_GET_PAISES = 'ENM_GET_PAISES';
export const ENM_GET_ESTADOS_CIVIS = 'ENM_GET_ESTADOS_CIVIS';
export const ENM_GET_RACAS = 'ENM_GET_RACAS';
export const ENM_GET_GRAUS_INSTRUCAO = 'ENM_GET_GRAUS_INSTRUCAO';
export const ENM_GET_GENEROS = 'ENM_GET_GENEROS';
export const ENM_GET_TIPOS_LOGRADOURO = 'ENM_GET_TIPOS_LOGRADOURO';
export const ENM_GET_FORMAS_PAGAMENTO = 'ENM_GET_FORMAS_PAGAMENTO';
export const ENM_GET_BANCOS = 'ENM_GET_BANCOS';
export const ENM_GET_TIPOS_CONTA = 'ENM_GET_TIPOS_CONTA';
export const ENM_GET_UNIDADES_FEDERATIVAS = 'ENM_GET_UNIDADES_FEDERATIVAS';
export const ENM_GET_TIPOS_CERTIDAO = 'ENM_GET_TIPOS_CERTIDAO';
export const ENM_GET_TIPOS_ADMISSAO = 'ENM_GET_TIPOS_ADMISSAO';
export const ENM_GET_VINCULOS = 'ENM_GET_VINCULOS';
export const ENM_GET_CATEGORIAS = 'ENM_GET_CATEGORIAS';
export const ENM_GET_NOVASCATEGORIAS = 'ENM_GET_NOVASCATEGORIAS';
export const ENM_GET_PARAMETROS_SALARIAL = 'ENM_GET_PARAMETROS_SALARIAL';
export const ENM_GET_TIPOS_PAGAMENTO = 'ENM_GET_TIPOS_PAGAMENTO';
export const ENM_GET_TIPOS_SALARIO = 'ENM_GET_TIPOS_SALARIO';
export const ENM_GET_JORNADAS_TRABALHO = 'ENM_GET_JORNADAS_TRABALHO';
export const ENM_GET_FORMAS_HORARIO_TRABALHO = 'ENM_GET_FORMAS_HORARIO_TRABALHO';
export const ENM_GET_FORMAS_TRABALHO = 'ENM_GET_FORMAS_TRABALHO';
export const ENM_GET_INDICATIVOS_ADMISSAO = 'ENM_GET_INDICATIVOS_ADMISSAO';
export const ENM_GET_TIPOS_INCLUSAO_CONTRATO = 'ENM_GET_TIPOS_INCLUSAO_CONTRATO';
export const ENM_GET_MOTIVOS_CONTRACAO = 'ENM_GET_MOTIVOS_CONTRACAO';
export const ENM_GET_CLASSIFICACOES_ESTRANGEIRO = 'ENM_GET_CLASSIFICACOES_ESTRANGEIRO';
export const ENM_GET_TIPOS_AFASTAMENTO = 'ENM_GET_TIPOS_AFASTAMENTO';
export const ENM_GET_TIPOS_AVISO = 'ENM_GET_TIPOS_AVISO';
export const ENM_GET_TIPOS_REDUCAO = 'ENM_GET_TIPOS_REDUCAO';
export const ENM_GET_RELACOES_DEPENDENCIA = 'ENM_GET_RELACOES_DEPENDENCIA';
export const ENM_GET_PARENTESCOS = 'ENM_GET_PARENTESCOS';
export const ENM_GET_LOCAIS_SERVICO = 'ENM_GET_LOCAIS_SERVICO';
export const ENM_GET_TIPOS_FOLHA = 'ENM_GET_TIPOS_FOLHA';
export const ENM_GET_TIPOS_FICHA_FINANCEIRA = 'ENM_GET_TIPOS_FICHA_FINANCEIRA';
export const ENM_GET_PERIODOS = 'ENM_GET_PERIODOS';
export const ENM_GET_TIPOS_FOLHA_RESUMO = 'ENM_GET_TIPOS_FOLHA_RESUMO';
export const ENM_GET_TIPOS_DIA = 'ENM_GET_TIPOS_DIA';
export const ENM_GET_UNIDADES = 'ENM_GET_UNIDADES';
export const ENM_GET_TIPOS_CONTRIBUINTE = 'ENM_GET_TIPOS_CONTRIBUINTE';
export const ENM_GET_CATEGORIAS_CONTRIBUINTE = 'ENM_GET_CATEGORIAS_CONTRIBUINTE';
export const ENM_GET_TIMEZONE = 'ENM_GET_TIMEZONE';
export const ENM_GET_SITUACOES = 'ENM_GET_SITUACOES';
export const ENM_GET_TIPOS_MOTIVO = 'ENM_GET_TIPOS_MOTIVO';
export const ENM_GET_CONTRATO_PRAZO = 'ENM_GET_CONTRATO_PRAZO';
export const ENM_GET_NIVEL_ESTAGIO = 'ENM_GET_NIVEL_ESTAGIO';
export const ENM_GET_NATUREZA_ESTAGIO = 'ENM_GET_NATUREZA_ESTAGIO';
export const ENM_GET_DESCONTO_IMPOSTO = 'ENM_GET_DESCONTO_IMPOSTO';
export const ENM_GET_TIPOS_CBO = 'ENM_GET_TIPOS_CBO';
// #endregion

// #region EMPREGADO

export const EPG_SET_ESTADO_INICIAL = 'EPG_SET_ESTADO_INICIAL';
export const EPG_PROCESSANDO_EMPREGADO = 'EPG_PROCESSANDO_EMPREGADO';
export const EPG_SHOW_MODAL_DEPENDENTE = 'EPG_SHOW_MODAL_DEPENDENTE';
export const EPG_GET_EMPREGADOS = 'EPG_GET_EMPREGADOS';
export const EPG_GET_EMPREGADOS_ATIVOS = 'EPG_GET_EMPREGADOS_ATIVOS';
export const EPG_SET_SELECTED_DEPENDENTE = 'EPG_SET_SELECTED_DEPENDENTE';
export const EPG_GET_DEPARTAMENTOS = 'EPG_GET_DEPARTAMENTOS';
export const EPG_GET_CARGOS = 'EPG_GET_CARGOS';
export const EPG_GET_SECOES = 'EPG_GET_SECOES';
export const EPG_GET_HORARIOS_TRABALHO = 'EPG_GET_HORARIOS_TRABALHO';
export const EPG_GET_ESCALAS_REVEZAMENTO = 'EPG_GET_ESCALAS_REVEZAMENTO';
export const EPG_GET_HORARIOS_SELECIONADO = 'EPG_GET_HORARIOS_SELECIONADO';
export const EPG_GET_INTERVALOS_SELECIONADOS = 'EPG_GET_INTERVALOS_SELECIONADOS';
export const EPG_GET_SINDICATOS = 'EPG_GET_SINDICATOS';
export const EPG_GET_SINDICATOS_PARAMETROS = 'EPG_GET_SINDICATOS_PARAMETROS';
export const EPG_GET_BENEFICIOS = 'EPG_GET_BENEFICIOS';
export const EPG_GET_LIST_BENEFICIOS = 'EPG_GET_LIST_BENEFICIOS';
export const EPG_GET_LOTACOES_TRIBUTARIAS = 'EPG_GET_LOTACOES_TRIBUTARIAS';
export const EPG_SET_ESTADO_INICIAL_DEPENDENTES = 'EPG_SET_ESTADO_INICIAL_DEPENDENTES';
export const EPG_GET_DEPENTENTES = 'EPG_GET_DEPENTENTES';
export const EPG_PROCESSANDO_DEPENDENTES = 'EPG_PROCESSANDO_DEPENDENTES';
export const EPG_SHOW_MODAL_CONTRATO_INTERMITENTE = 'EPG_SHOW_MODAL_CONTRATO_INTERMITENTE';
export const EPG_GET_CONTRATOS_INTERMITENTE = 'EPG_GET_CONTRATOS_INTERMITENTE';
export const EPG_PROCESSANDO_CONTRATOS_INTERMITENTE = 'EPG_PROCESSANDO_CONTRATOS_INTERMITENTE';
export const EPG_SET_ESTADO_INICIAL_CONTRATOS_INTERMITENTE =
  'EPG_SET_ESTADO_INICIAL_CONTRATOS_INTERMITENTE';
export const EPG_GET_ARQUIVOS = 'EPG_GET_ARQUIVOS';
export const EPG_SET_ESTADO_INICIAL_ANEXOS = 'EPG_SET_ESTADO_INICIAL_ANEXOS';
export const EPG_SET_PERIODO_AQUISITIVO = 'EPG_SET_PERIODO_AQUISITIVO';
export const EPG_GET_GESTORES_SUCCESS = 'EPG_GET_GESTORES_SUCCESS';

// #endregion

// #region PROGRAMAÇÃO DE FÉRIAS

export const PRG_SET_ESTADO_INICIAL = 'PRG_SET_ESTADO_INICIAL';
export const PRG_PROCESSANDO_PROGRAMACAO_FERIAS = 'PRG_PROCESSANDO_PROGRAMACAO_FERIAS';
export const PRG_GET_PROGRAMACOES_FERIAS = 'PRG_GET_PROGRAMACOES_FERIAS';
export const PRG_SET_ARQUIVOS_IMPORTACAO = 'PRG_SET_ARQUIVOS_IMPORTACAO';

// #endregion

// #region AFASTAMENTO

export const AFA_SET_ESTADO_INICIAL = 'AFA_SET_ESTADO_INICIAL';
export const AFA_PROCESSANDO_AFASTAMENTO = 'AFA_PROCESSANDO_AFASTAMENTO';
export const AFA_GET_AFASTAMENTOS = 'AFA_GET_AFASTAMENTOS';

// #endregion

// #region AVISO PRÉVIO

export const AVP_SET_ESTADO_INICIAL = 'AVP_SET_ESTADO_INICIAL';
export const AVP_PROCESSANDO_AVISO_PREVIO = 'AVP_PROCESSANDO_AVISO_PREVIO';
export const AVP_GET_AVISOS_PREVIOS = 'AVP_GET_AVISOS_PREVIOS';
export const AVP_SET_STATE_REDUCAO = 'AVP_SET_STATE_REDUCAO';
export const AVP_SET_REQUIRED_DATA_AVISO = 'AVP_SET_REQUIRED_DATA_AVISO';

// #endregion

// #region FOLHA DE PONTO

export const FPT_SET_ESTADO_INICIAL = 'FPT_SET_ESTADO_INICIAL';
export const FPT_GET_FOLHAS_PONTO = 'FPT_GET_FOLHAS_PONTO';
export const FPT_PROCESSANDO_FOLHAS_PONTO = 'FPT_PROCESSANDO_FOLHAS_PONTO';

// #endregion

// #region RECIBO

export const REC_SET_ESTADO_INICIAL = 'REC_SET_ESTADO_INICIAL';
export const REC_GET_RECIBOS = 'REC_GET_RECIBOS';
export const REC_PROCESSANDO_RECIBOS = 'REC_PROCESSANDO_RECIBOS';

// #endregion

// #region AVISO DE FÉRIAS

export const AVF_SET_ESTADO_INICIAL = 'AVF_SET_ESTADO_INICIAL';
export const AVF_GET_AVISOS_FERIAS = 'AVF_GET_AVISOS_FERIAS';
export const AVF_PROCESSANDO_AVISOS_FERIAS = 'AVF_PROCESSANDO_AVISOS_FERIAS';

// #endregion

// #region FICHA FINANCEIRA

export const FIF_SET_ESTADO_INICIAL = 'FIF_SET_ESTADO_INICIAL';
export const FIF_GET_FICHAS_FINANCEIRA = 'FIF_GET_FICHAS_FINANCEIRA';
export const FIF_PROCESSANDO_FICHAS_FINANCEIRA = 'FIF_PROCESSANDO_FICHAS_FINANCEIRA';

// #endregion

// #region INFORME DE RENDIMENTOS

export const INR_SET_ESTADO_INICIAL = 'INR_SET_ESTADO_INICIAL';
export const INR_GET_INFORMES_RENDIMENTOS = 'INR_GET_INFORMES_RENDIMENTOS';
export const INR_PROCESSANDO_INFORMES_RENDIMENTOS = 'INR_PROCESSANDO_INFORMES_RENDIMENTOS';

// #endregion

// #region LÍQUIDO DA FOLHA

export const LQF_SET_ESTADO_INICIAL = 'LQF_SET_ESTADO_INICIAL';
export const LQF_GET_LIQUIDOS_FOLHA = 'LQF_GET_LIQUIDOS_FOLHA';
export const LQF_PROCESSANDO_LIQUIDOS_FOLHA = 'LQF_PROCESSANDO_LIQUIDOS_FOLHA';

// #endregion

// #region MAPA DA FOLHA

export const MPF_SET_ESTADO_INICIAL = 'MPF_SET_ESTADO_INICIAL';
export const MPF_GET_MAPAS_FOLHA = 'MPF_GET_MAPAS_FOLHA';
export const MPF_PROCESSANDO_MAPAS_FOLHA = 'MPF_PROCESSANDO_MAPAS_FOLHA';

// #endregion

// #region RESUMO DA FOLHA

export const RSF_SET_ESTADO_INICIAL = 'RSF_SET_ESTADO_INICIAL';
export const RSF_GET_RESUMOS_FOLHA = 'RSF_GET_RESUMOS_FOLHA';
export const RSF_PROCESSANDO_RESUMOS_FOLHA = 'RSF_PROCESSANDO_RESUMOS_FOLHA';

// #endregion

// #region RESUMO DA FOLHA

export const FRE_SET_ESTADO_INICIAL = 'FRE_SET_ESTADO_INICIAL';
export const FRE_GET_FREQUENCIAS = 'FRE_GET_FREQUENCIAS';
export const FRE_PROCESSANDO_FREQUENCIAS = 'FRE_PROCESSANDO_FREQUENCIAS';
export const FRE_SET_TOTALIZADORES = 'FRE_SET_TOTALIZADORES';
export const FRE_GET_FREQUENCIA_DIA = 'FRE_GET_FREQUENCIA_DIA';
export const FRE_SET_HORAS_UTEIS = 'FRE_SET_HORAS_UTEIS';
export const FRE_SET_HORAS_TRABALHADAS = 'FRE_SET_HORAS_TRABALHADAS';
export const FRE_SET_SALDO_HORAS = 'FRE_SET_SALDO_HORAS';
export const FRE_SET_VISIBILIDADE_MODAL_LOCALIZACAO = 'FRE_SET_VISIBILIDADE_MODAL_LOCALIZACAO';

// #endregion

// #region EVENTOS DO PONTO

export const EVP_SET_ESTADO_INICIAL = 'EVP_SET_ESTADO_INICIAL';
export const EVP_GET_EVENTOS_PONTO = 'EVP_GET_EVENTOS_PONTO';
export const EVP_PROCESSANDO_EVENTOS_PONTO = 'EVP_PROCESSANDO_EVENTOS_PONTO';

// #endregion

// #region PROGRAMAÇÃO DE EVENTOS

export const PRE_SET_ESTADO_INICIAL = 'PRE_SET_ESTADO_INICIAL';
export const PRE_PROCESSANDO_PROGRAMACAO_EVENTOS = 'PRE_PROCESSANDO_PROGRAMACAO_EVENTOS';
export const PRE_GET_PROGRAMACOES_EVENTOS = 'PRE_GET_PROGRAMACOES_EVENTOS';
export const PRE_PROCESSANDO_EVENTOS = 'PRE_PROCESSANDO_EVENTOS';
export const PRE_GET_EVENTOS = 'PRE_GET_EVENTOS';
export const PRE_SET_ARQUIVOS_IMPORTACAO = 'PRE_SET_ARQUIVOS_IMPORTACAO';
export const PRE_SET_DATA_SOURCE = 'PRE_SET_DATA_SOURCE';

// #endregion

// #region JUSTIFICATIVA DE FALTA

export const JUS_SET_ESTADO_INICIAL = 'JUS_SET_ESTADO_INICIAL';
export const JUS_GET_JUSTIFICATIVAS_FALTA = 'JUS_GET_JUSTIFICATIVAS_FALTA';
export const JUS_PROCESSANDO_JUSTIFICATIVA_FALTA = 'JUS_PROCESSANDO_JUSTIFICATIVA_FALTA';
export const JUS_GET_FREQUENCIA = 'JUS_GET_FREQUENCIA';
export const JUS_SET_MODAL_VISIBLE_RECUSAR = 'JUS_SET_MODAL_VISIBLE_RECUSAR';
export const JUS_SET_ACEITAR_SUCESSO = 'JUS_SET_ACEITAR_SUCESSO';
export const JUS_SET_ACEITAR_FALHA = 'JUS_SET_ACEITAR_FALHA';
export const JUS_SET_QUANTIDADE_PENDENTES = 'JUS_SET_QUANTIDADE_PENDENTES';

// #endregion

// #region PONTUALIDADE

export const PON_SET_ESTADO_INICIAL = 'PON_SET_ESTADO_INICIAL';
export const PON_SET_PRESENCA = 'PON_SET_PRESENCA';
export const PON_PROCESSANDO_PONTUALIDADE = 'PON_PROCESSANDO_PONTUALIDADE';
export const PON_SET_MODAL_VISIBLE = 'PON_SET_MODAL_VISIBLE';
export const PON_SET_DATA = 'PON_SET_DATA';
export const PON_SET_PONTUALIDADE = 'PON_SET_PONTUALIDADE';

// #endregion

// #region PONTUALIDADE

export const IND_SET_HEADCOUNT = 'IND_SET_HEADCOUNT';
export const IND_SET_SENIORIDADE = 'IND_SET_SENIORIDADE';
export const IND_SET_ESTADO_INICIAL = 'IND_SET_ESTADO_INICIAL';
export const IND_PROCESSANDO_INDICADORES = 'IND_PROCESSANDO_INDICADORES';
export const IND_SET_MODAL = 'IND_SET_MODAL';
export const IND_SET_AFASTADOS = 'IND_SET_AFASTADOS';
export const IND_SET_AVISOS = 'IND_SET_AVISOS';

// #endregion

// #region CONTRIBUINTE INDIVIDUAL

export const CIL_SET_ESTADO_INICIAL = 'CIL_SET_ESTADO_INICIAL';
export const CIL_PROCESSANDO_CONTRIBUINTE = 'CIL_PROCESSANDO_CONTRIBUINTE';
export const CIL_SET_CONTRIBUINTES = 'CIL_SET_CONTRIBUINTES';

// #endregion

// #region AFASTAMENTO

export const GES_SET_ESTADO_INICIAL = 'GES_SET_ESTADO_INICIAL';
export const GES_PROCESSANDO_GESTOR = 'GES_PROCESSANDO_GESTOR';
export const GES_SET_GESTORES = 'GES_SET_GESTORES';
export const GES_SET_PESSOAS = 'GES_SET_PESSOAS';
export const GES_SET_PESSOAS_SELECIONADAS = 'GES_SET_PESSOAS_SELECIONADAS';
export const GES_SET_MODAL_VISIBLE = 'GES_SET_MODAL_VISIBLE';

// #endregion

// #region Onboarding - candidato

export const CAN_SET_CANDIDATO = 'CAN_SET_CANDIDATO';
export const CAN_SET_CANDIDATOS = 'CAN_SET_CANDIDATOS';
export const CAN_SET_ESTADO_INICIAL = 'CAN_SET_ESTADO_INICIAL';
export const CAN_SET_EMPRESAS_LIST = 'CAN_SET_EMPRESAS_LIST';
export const CAN_SET_CARDS = 'CAN_SET_CARDS';
export const CAN_SET_MODAL_VISIBLE = 'CAN_SET_MODAL_VISIBLE';
export const CAN_PROCESSANDO_CANDIDATO = 'CAN_PROCESSANDO_CANDIDATO';
export const EPG_GET_CANDIDATO_SINDICATOS_PARAMETROS = 'EPG_GET_CANDIDATO_SINDICATOS_PARAMETROS';
export const CAN_SET_LINK = 'CAN_SET_LINK';
export const EPG_GET_CANDIDATO_BENEFICIOS = 'EPG_GET_CANDIDATO_BENEFICIOS';
export const EPG_GET_LIST_CANDIDATO_BENEFICIOS = 'EPG_GET_LIST_CANDIDATO_BENEFICIOS';

// #region AFASTAMENTO

export const MOT_SET_MOTIVOS = 'MOT_SET_MOTIVOS';
export const MOT_PROCESSANDO_MOTIVOS = 'MOT_PROCESSANDO_MOTIVOS';
export const MOT_SET_ESTADO_INICIAL = 'MOT_SET_ESTADO_INICIAL';

// #endregion

// #region GESTÃO DE FREQUENCIA

export const GFR_SET_ESTADO_INICIAL = 'GFR_SET_ESTADO_INICIAL';
export const GFR_SET_AUSENTE = 'GFR_SET_AUSENTE';
export const GFR_SET_IRREGULARES = 'GFR_SET_IRREGULARES';
export const GFR_PROCESSANDO_FREQUENCIA = 'GFR_PROCESSANDO_FREQUENCIA';
export const GFR_SET_MODAL_VISIBLE = 'GFR_SET_MODAL_VISIBLE';
export const GFR_SET_DATA = 'GFR_SET_DATA';
export const GFR_PROCESSANDO_LOCALIZACAO = 'GFR_PROCESSANDO_LOCALIZACAO';
export const GFR_SET_LOCALIZACAO_FICTICIA = 'GFR_SET_LOCALIZACAO_FICTICIA';
export const GFR_SET_FREQUENCIAS = 'GFR_SET_FREQUENCIAS';
export const GFR_SET_DISPOSITIVOS_NAO_RECONHECIDOS = 'GFR_SET_DISPOSITIVOS_NAO_RECONHECIDOS';
export const GFR_PROCESSANDO_DISPOSITIVOS = 'GFR_PROCESSANDO_DISPOSITIVOS';
export const GFR_SET_DISPOSITIVO_PADRAO = 'GFR_SET_DISPOSITIVO_PADRAO';

// #endregion

// #region CONFIGURAÇÃO BATIDA REMOTA

export const CBR_SET_ESTADO_INICIAL = 'CBR_SET_ESTADO_INICIAL';
export const CBR_PROCESSANDO = 'CBR_PROCESSANDO';
export const CBR_SET_COLABORADORES = 'CBR_SET_COLABORADORES';
export const CBR_SET_FILTRO = 'CBR_SET_FILTRO';
export const CBR_SET_PLANO_CONTRATADO = 'CBR_SET_PLANO_CONTRATADO';
export const CBR_SET_EMPREGADOS_CONFIGURADOS = 'CBR_SET_EMPREGADOS_CONFIGURADOS';
export const CBR_SET_EMPREGADOS_ATIVOS = 'CBR_SET_EMPREGADOS_ATIVOS';
export const CBR_SET_RECONHECIMENTO_FACIAL = 'CBR_SET_RECONHECIMENTO_FACIAL';
export const CBR_SET_EMPREGADOS_CONFIGURADOS_RECONHECIMENTO_FACIAL =
  'CBR_SET_EMPREGADOS_CONFIGURADOS_RECONHECIMENTO_FACIAL';
export const CBR_SET_DADOS_EMPRESA = 'CBR_SET_DADOS_EMPRESA';

// #endregion

// #region EMPRESA

export const EMP_SET_ESTADO_INICIAL = 'EMP_SET_ESTADO_INICIAL';
export const EMP_SET_PESSOAS = 'EMP_SET_PESSOAS';
export const EMP_PROCESSANDO_PESSOAS = 'EMP_PROCESSANDO_PESSOAS';
export const EMP_SET_CURRENT_STEP = 'EMP_SET_CURRENT_STEP';
export const EMP_CREATE_SET_INITIAL_STATE = 'EMP_CREATE_SET_INITIAL_STATE';

// #endregion

// #region CONFIGURACAO USUARIO

export const CFG_ESTADO_INICIAL_SUCESSO = 'CFG_ESTADO_INICIAL_SUCESSO';
export const CFG_ATUALIZAR_FOTO_SUCESSO = 'CFG_ATUALIZAR_FOTO_SUCESSO';
export const CFG_ATUALIZAR_FOTO_FALHA = 'CFG_ATUALIZAR_FOTO_FALHA';
export const CFG_DELETAR_FOTO_SUCESSO = 'CFG_DELETAR_FOTO_SUCESSO';
export const CFG_DELETAR_FOTO_FALHA = 'CFG_DELETAR_FOTO_FALHA';
export const CFG_CONFIGURACAO_NOTIFICACAO_SUCESSO = 'CFG_CONFIGURACAO_NOTIFICACAO_SUCESSO';
export const CFG_CONFIGURACAO_NOTIFICACAO_FALHA = 'CFG_CONFIGURACAO_NOTIFICACAO_FALHA';
export const CFG_ALTERAR_CONFIGURACAO_NOTIFICACAO_SUCESSO =
  'CFG_ALTERAR_CONFIGURACAO_NOTIFICACAO_SUCESSO';
export const CFG_ALTERAR_CONFIGURACAO_NOTIFICACAO_FALHA =
  'CFG_ALTERAR_CONFIGURACAO_NOTIFICACAO_FALHA';

// #endregion

// #region COLETA DE PONTO COLETIVA

export const COC_REGISTRO_COLETIVO_SUCESSO = 'COC_REGISTRO_COLETIVO_SUCESSO';
export const COC_REGISTRO_COLETIVO_FALHA = 'COC_REGISTRO_COLETIVO_FALHA';

// #endregion

// #region INTEGRAÇÕES

export const INT_SET_ESTADO_INICIAL = 'INT_SET_ESTADO_INICIAL';
export const INT_SET_EVENTO = 'INT_SET_EVENTO';
export const INT_PROCESSANDO_INTEGRACAO = 'INT_PROCESSANDO_INTEGRACAO';

// #region LANÇAMENTO DE FAIXAS SALARIAIS

export const LFS_SET_ESTADO_INICIAL = 'LFS_SET_ESTADO_INICIAL';
export const LFS_PROCESSANDO_LANCAMENTO_FAIXA_SALARIAL =
  'LFS_PROCESSANDO_LANCAMENTO_FAIXA_SALARIAL';
export const LFS_GET_LANCAMENTOS_FAIXAS_SALARIAIS = 'LFS_GET_LANCAMENTOS_FAIXAS_SALARIAIS';

// #endregion
